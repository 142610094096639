<template>
  <div class="mb-2">
    <div
      class="text-black"
      style="font-weight: 500; font-size: 16px; margin: 12px 0px"
    >
      Целевые действия
    </div>
    <div v-for="(goal, index) in goals" :key="index">
      <div class="d-flex mb-1 align-items-center">
        <span class="font-weight-bolder">{{ index + 1 }}.</span>
        <feather-icon
          @click="del(index)"
          size="14"
          icon="TrashIcon"
          class="text-danger ml-1"
          style="cursor: pointer"
        />
      </div>
      <b-row>
        <b-col cols="12" md="4" lg="4">
          <b-form-group class="form-group-with-label">
            <validation-provider #default="{ errors }" rules="required">
              <span
                :class="{
                  'text-muted': errors.length > 0 ? false : true,
                  'font-weight-bolder': errors.length > 0 ? true : false,
                  'text-danger': errors.length > 0 ? true : false,
                }"
                style="font-size: 12px"
                >Название</span
              >
              <b-form-input type="text" v-model="goal.title" />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" lg="4">
          <b-form-group class="form-group-with-label">
            <validation-provider #default="{ errors }" :rules="goals.length == 1 ? '' : 'required'">
              <span
                :class="{
                  'text-muted': errors.length > 0 ? false : true,
                  'font-weight-bolder': errors.length > 0 ? true : false,
                  'text-danger': errors.length > 0 ? true : false,
                }"
                style="font-size: 12px"
                >Внешний идентификатор{{goals.length == 1 ? '(необязательно)' : ''}}</span
              >
              <b-form-input type="text" v-model="goal.external_id" />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" lg="4">
          <b-form-group class="form-group-with-label">
            <validation-provider #default="{ errors }" rules="">
              <span
                :class="{
                  'text-muted': errors.length > 0 ? false : true,
                  'font-weight-bolder': errors.length > 0 ? true : false,
                  'text-danger': errors.length > 0 ? true : false,
                }"
                style="font-size: 12px"
                >Описание(необязательно)</span
              >
              <b-form-input type="text" v-model="goal.description" />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3" lg="3">
          <b-form-group class="form-group-with-label">
            <validation-provider #default="{ errors }" rules="required">
              <span
                :class="{
                  'text-muted': errors.length > 0 ? false : true,
                  'font-weight-bolder': errors.length > 0 ? true : false,
                  'text-danger': errors.length > 0 ? true : false,
                }"
                style="font-size: 12px"
                >Ставка платформы {{ goal.is_percentage ? "(в %)" : "" }}</span
              >
              <b-form-input type="number" v-model="goal.platform_income" />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" lg="3">
          <b-form-group class="form-group-with-label">
            <validation-provider #default="{ errors }" rules="required">
              <span
                :class="{
                  'text-muted': errors.length > 0 ? false : true,
                  'font-weight-bolder': errors.length > 0 ? true : false,
                  'text-danger': errors.length > 0 ? true : false,
                }"
                style="font-size: 12px"
                >Ставка веб-мастера
                {{ goal.is_percentage ? "(в %)" : "" }}</span
              >
              <b-form-input type="number" v-model="goal.web_income" />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2" lg="2">
          <b-form-checkbox
            v-model="goal.is_percentage"
            :value="true"
            :unchecked-value="false"
            class="custom-control-primary"
            switch
            inline
          >
            Доход в %
          </b-form-checkbox>
        </b-col>
      </b-row>
      <div class="mt-2 mb-2 ml-0 separator"></div>
    </div>
    <b-link class="ml-1" @click="add"> Добавить цель </b-link>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  data() {
    return {};
  },
  props: {
    goals: Array,
  },
  methods: {
    add() {
      this.goals.push({
        external_id: "",
        platform_income: "",
        web_income: "",
        is_percentage: false,
        title: "",
        description: "",
      });
    },
    del(i) {
      this.goals.splice(i, 1);
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  watch: {
    goals: {
      deep: true,
      handler(bef) {
        this.$emit("afterChangeGoals", this.goals);
      },
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.form-group-with-label {
  padding: 6px 20px;
}
</style>
