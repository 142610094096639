var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"text-black",staticStyle:{"font-weight":"500","font-size":"16px","margin":"12px 0px"}},[_vm._v(" Целевые действия ")]),_vm._l((_vm.goals),function(goal,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex mb-1 align-items-center"},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(index + 1)+".")]),_c('feather-icon',{staticClass:"text-danger ml-1",staticStyle:{"cursor":"pointer"},attrs:{"size":"14","icon":"TrashIcon"},on:{"click":function($event){return _vm.del(index)}}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{class:{
                'text-muted': errors.length > 0 ? false : true,
                'font-weight-bolder': errors.length > 0 ? true : false,
                'text-danger': errors.length > 0 ? true : false,
              },staticStyle:{"font-size":"12px"}},[_vm._v("Название")]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(goal.title),callback:function ($$v) {_vm.$set(goal, "title", $$v)},expression:"goal.title"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":_vm.goals.length == 1 ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('span',{class:{
                'text-muted': errors.length > 0 ? false : true,
                'font-weight-bolder': errors.length > 0 ? true : false,
                'text-danger': errors.length > 0 ? true : false,
              },staticStyle:{"font-size":"12px"}},[_vm._v("Внешний идентификатор"+_vm._s(_vm.goals.length == 1 ? '(необязательно)' : ''))]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(goal.external_id),callback:function ($$v) {_vm.$set(goal, "external_id", $$v)},expression:"goal.external_id"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('span',{class:{
                'text-muted': errors.length > 0 ? false : true,
                'font-weight-bolder': errors.length > 0 ? true : false,
                'text-danger': errors.length > 0 ? true : false,
              },staticStyle:{"font-size":"12px"}},[_vm._v("Описание(необязательно)")]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(goal.description),callback:function ($$v) {_vm.$set(goal, "description", $$v)},expression:"goal.description"}})]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('span',{class:{
                'text-muted': errors.length > 0 ? false : true,
                'font-weight-bolder': errors.length > 0 ? true : false,
                'text-danger': errors.length > 0 ? true : false,
              },staticStyle:{"font-size":"12px"}},[_vm._v("Ставка платформы "+_vm._s(goal.is_percentage ? "(в %)" : ""))]),_c('b-form-input',{attrs:{"type":"number"},model:{value:(goal.platform_income),callback:function ($$v) {_vm.$set(goal, "platform_income", $$v)},expression:"goal.platform_income"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('span',{class:{
                'text-muted': errors.length > 0 ? false : true,
                'font-weight-bolder': errors.length > 0 ? true : false,
                'text-danger': errors.length > 0 ? true : false,
              },staticStyle:{"font-size":"12px"}},[_vm._v("Ставка веб-мастера "+_vm._s(goal.is_percentage ? "(в %)" : ""))]),_c('b-form-input',{attrs:{"type":"number"},model:{value:(goal.web_income),callback:function ($$v) {_vm.$set(goal, "web_income", $$v)},expression:"goal.web_income"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":true,"unchecked-value":false,"switch":"","inline":""},model:{value:(goal.is_percentage),callback:function ($$v) {_vm.$set(goal, "is_percentage", $$v)},expression:"goal.is_percentage"}},[_vm._v(" Доход в % ")])],1)],1),_c('div',{staticClass:"mt-2 mb-2 ml-0 separator"})],1)}),_c('b-link',{staticClass:"ml-1",on:{"click":_vm.add}},[_vm._v(" Добавить цель ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }