<template>
  <b-overlay
    :show="is_loading"
    variant="transparent"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card no-body>
      <b-card-header>
        <b-card-title> Создание предложения </b-card-title>
      </b-card-header>
      <b-card-body>
        <validation-observer ref="form">
          <b-form>
            <div
              class="text-black"
              style="font-weight: 500; font-size: 16px; margin: 12px 0px"
            >
              Расположение на витрине
            </div>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Раздел</span
                    >
                    <v-select
                      :options="typeAllocation"
                      v-model="form.showcase_location"
                      label="title"
                      :clearable="false"
                      placeholder="Выбрать раздел"
                    >
                      <template slot="no-options">
                        Нет результатов поиcка
                      </template>
                    </v-select>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <div
              class="text-black"
              style="font-weight: 500; font-size: 16px; margin: 12px 0px"
            >
              Основное
            </div>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Доступ к предложению</span
                    >
                    <v-select
                      :options="statesAccess"
                      v-model="form.state_access"
                      label="title"
                      :clearable="false"
                      placeholder="Выбрать"
                    >
                      <template slot="no-options">
                        Нет результатов поиcка
                      </template>
                    </v-select>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
              
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Заголовок поста</span
                    >
                    <b-form-input id="name" v-model="form.title" />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="4" lg="4">
                <b-form-group class="form-group-with-label">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Ставка платформы
                      {{ form.is_percentage_income ? "(в %)" : "" }}</span
                    >
                    <b-form-input id="amount" type="number" v-model="form.platform_amount" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <b-form-group class="form-group-with-label">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Ставка веб-мастера
                      {{ form.is_percentage_income ? "(в %)" : "" }}</span
                    >
                    <b-form-input id="amount" type="number" v-model="form.amount" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="2" lg="2">
                <b-form-checkbox
                  v-model="form.is_percentage_income"
                  :value="1"
                  :unchecked-value="0"
                  class="custom-control-primary"
                  switch
                  inline
                >
                  Доход в %
                </b-form-checkbox>
              </b-col>
               <b-col cols="12" md="6" lg="6">
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider
                    #default="{ errors }"
                    rules="required|url"
                  >
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Ссылка партнера</span
                    >
                    <b-form-input id="amount" v-model="form.url" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider
                    #default="{ errors }"
                    rules="required|integer"
                  >
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Срок подтверждения</span
                    >
                    <b-form-input
                      id="conformation_period"
                      v-model="form.conformation_period"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Категория</span
                    >
                    <v-select
                      :options="categories"
                      v-model="form.category"
                      label="name"
                      :clearable="false"
                      placeholder="Выбрать категорию"
                    >
                      <template slot="no-options">
                        Нет результатов поиcка
                      </template>
                    </v-select>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Компания</span
                    >
                    <v-select
                      :options="companies"
                      v-model="form.company"
                      label="name"
                      :clearable="false"
                      placeholder="Выбрать компанию"
                    >
                      <template slot="no-options">
                        Нет результатов поиcка
                      </template>
                    </v-select>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <div class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Описание</span
                    >
                    <quill-editor
                      id="description_content"
                      v-model="form.description_content"
                      class="form-control font-weight-normal p-0"
                      style="height: auto"
                      :options="snowOption"
                    />
                  </validation-provider>
                </div>
              </b-col>
            </b-row>

            <goals-widget :goals="form.goals" @afterChangeGoals="afterChangeGoals" />

            <div
              class="text-black"
              style="font-weight: 500; font-size: 16px; margin: 12px 0px"
            >
              Гео (пропустить если Вся РФ)
            </div>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Тип выбора</span
                    >
                    <v-select
                      :options="typesSelectedCities"
                      v-model="form.type_cities_selected"
                      label="title"
                      :clearable="false"
                      placeholder="Выбрать вариант выбора городов"
                    >
                      <template slot="no-options">
                        Нет результатов поиcка
                      </template>
                    </v-select>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="
                  form.type_cities_selected.id == 'single' ||
                  form.type_cities_selected.id == 'everything_except'
                "
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Выберите города</span
                    >
                    <v-select
                      :options="cities"
                      v-model="form.cities"
                      label="name"
                      :multiple="true"
                      :clearable="true"
                      placeholder="Выбрать город"
                    >
                      <template slot="no-options">
                        Нет результатов поиcка
                      </template>
                    </v-select>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-else-if="form.type_cities_selected.id == 'list'"
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Список (через запятую)</span
                    >
                    <b-form-input
                      class="text-weight-normal"
                      placeholder="Список городов через запятую"
                      v-model="form.cities"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <div
              class="text-black"
              style="font-weight: 500; font-size: 16px; margin: 12px 0px"
            >
              Варианты интеграций
            </div>

             <b-row>
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Выберите интеграции которые будут настроены для оффера</span
                    >
                    <v-select
                      :options="integrations"
                      v-model="form.integrations"
                      label="name"
                      :multiple="true"
                      :clearable="true"
                      placeholder="Выбрать интеграцию"
                    >
                      <template slot="no-options">
                        Нет результатов поиcка
                      </template>
                    </v-select>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>


            <div
              class="text-black"
              style="font-weight: 500; font-size: 16px; margin: 12px 0px"
            >
              Параметры ссылки
            </div>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="required">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Ключ идентификатора заявки(обязательно)</span
                    >
                    <b-form-input id="name" v-model="form.key_order" />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <b-form-group class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Ключ идентификатора веб-мастера</span
                    >
                    <b-form-input id="name" v-model="form.key_web_master" />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <div
              class="text-black"
              style="font-weight: 500; font-size: 16px; margin: 12px 0px"
            >
              Типы трафика
            </div>

            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-table-simple class="table-striped">
                  <b-thead>
                    <b-tr>
                      <b-td
                        class="text-black font-weight-bolder"
                        style="background: #e1ffe6"
                        >Разрешено</b-td
                      >
                      <!-- <b-td></b-td> -->
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(item, index) in traffics.allowed"
                      :key="index"
                    >
                      <b-td style="background: #fff">
                        <b-form-group class="form-group-with-label" style="padding: 6px 20px; margin-bottom: 0px;">
                          <validation-provider #default="{ errors }" rules="">
                            <span
                              :class="{
                                'text-muted': errors.length > 0 ? false : true,
                                'font-weight-bolder':
                                  errors.length > 0 ? true : false,
                                'text-danger': errors.length > 0 ? true : false,
                              }"
                              style="font-size: 12px"
                              >Название</span
                            >
                            <b-form-input
                              :id="`allowed_${index}`"
                              v-model="traffics.allowed[index]"
                            />
                          </validation-provider>
                        </b-form-group>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-table-simple class="table-striped">
                  <b-thead>
                    <b-tr>
                      <b-td
                        class="text-black font-weight-bolder"
                        style="background: #ffe8e1"
                        >Запрещено</b-td
                      >
                      <!-- <b-td></b-td> -->
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(item, index) in traffics.forbidden"
                      :key="index"
                    >
                      <b-td style="background: #fff">
                        <b-form-group class="form-group-with-label" style="padding: 6px 20px; margin-bottom: 0px;">
                          <validation-provider #default="{ errors }" rules="">
                            <span
                              :class="{
                                'text-muted': errors.length > 0 ? false : true,
                                'font-weight-bolder':
                                  errors.length > 0 ? true : false,
                                'text-danger': errors.length > 0 ? true : false,
                              }"
                              style="font-size: 12px"
                              >Название</span
                            >
                            <b-form-input
                              :id="`forbidden_${index}`"
                              v-model="traffics.forbidden[index]"
                            />
                          </validation-provider>
                        </b-form-group>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <div class="form-group-with-label">
                  <validation-provider #default="{ errors }" rules="">
                    <span
                      :class="{
                        'text-muted': errors.length > 0 ? false : true,
                        'font-weight-bolder': errors.length > 0 ? true : false,
                        'text-danger': errors.length > 0 ? true : false,
                      }"
                      style="font-size: 12px"
                      >Советы</span
                    >
                    <quill-editor
                      id="advice_content"
                      v-model="form.advice_content"
                      class="form-control font-weight-normal p-0"
                      style="height: auto"
                      :options="snowOption"
                    />
                  </validation-provider>
                </div>
              </b-col>
            </b-row>

            <div
              class="text-black"
              style="font-weight: 500; font-size: 16px; margin: 12px 0px"
            >
              Медиа
            </div>
            <div
              class="text-black"
              style="font-weight: 400; font-size: 14px; margin: 12px 0px"
            >
              <p class="mb-0">Баннер на витрине (668х340 px)</p>
            </div>
            <b-row>
              <b-col
                v-if="medias.showcase.length === 0"
                lg="6"
                @click="preferMediaPickerAction('showcase')"
                class="d-flex justify-content-center align-items-center flex-nowrap bg-gray"
                style="
                  padding: 12px;
                  height: 175px;
                  cursor: pointer;
                  border-radius: 4px;
                  margin: 0 12px;
                "
              >
                <span class="text-muted">
                  Кликните, чтобы добавить медиа объект к публикации
                </span>
              </b-col>

              <b-col
                v-else
                lg="6"
                @click="preferMediaPickerAction('showcase')"
                class="d-flex justify-content-start align-items-center flex-nowrap bg-gray"
                style="
                  padding: 12px;
                  height: 175px;
                  cursor: pointer;
                  border-radius: 4px;
                  margin: 0 12px;
                  overflow-y: hidden;
                  overflow-x: scroll;
                "
              >
                <div
                  v-for="(media, index) in medias.showcase"
                  :key="index"
                  @click="$event.stopPropagation()"
                  :style="getMediaPlaceholderStyle(media)"
                >
                  <div
                    @click="prefereMediaDeleteAction($event, index, 'showcase')"
                    class="bg-white"
                    style="
                      position: absolute;
                      text-align: center;
                      line-height: 30px;
                      pointer: cursor;
                      right: -10px;
                      top: -10px;
                      width: 32px;
                      height: 32px;
                      border-radius: 50%;
                    "
                  >
                    <feather-icon icon="XIcon" size="20" color="gray" />
                  </div>

                  <b-progress
                    v-if="media.id < 0"
                    :value="media.progress.loaded"
                    :max="media.progress.total"
                    show-progress
                    animated
                    style="width: 100%"
                  ></b-progress>
                </div>
              </b-col>
            </b-row>

            <div
              class="text-black"
              style="font-weight: 400; font-size: 14px; margin: 12px 0px"
            >
              <p class="mb-0">Баннер на экране офера (1200x220 px)</p>
            </div>
            <b-row>
              <b-col
                v-if="medias.offer_screen.length === 0"
                lg="6"
                @click="preferMediaPickerAction('offer_screen')"
                class="d-flex justify-content-center align-items-center flex-nowrap bg-gray"
                style="
                  padding: 12px;
                  height: 175px;
                  cursor: pointer;
                  border-radius: 4px;
                  margin: 0 12px;
                "
              >
                <span class="text-muted">
                  Кликните, чтобы добавить медиа объект к публикации
                </span>
              </b-col>

              <b-col
                v-else
                lg="6"
                @click="preferMediaPickerAction('offer_screen')"
                class="d-flex justify-content-start align-items-center flex-nowrap bg-gray"
                style="
                  padding: 12px;
                  height: 175px;
                  cursor: pointer;
                  border-radius: 4px;
                  margin: 0 12px;
                  overflow-y: hidden;
                  overflow-x: scroll;
                "
              >
                <div
                  v-for="(media, index) in medias.offer_screen"
                  :key="index"
                  @click="$event.stopPropagation()"
                  :style="getMediaPlaceholderStyle(media)"
                >
                  <div
                    @click="
                      prefereMediaDeleteAction($event, index, 'offer_screen')
                    "
                    class="bg-white"
                    style="
                      position: absolute;
                      text-align: center;
                      line-height: 30px;
                      pointer: cursor;
                      right: -10px;
                      top: -10px;
                      width: 32px;
                      height: 32px;
                      border-radius: 50%;
                    "
                  >
                    <feather-icon icon="XIcon" size="20" color="gray" />
                  </div>

                  <b-progress
                    v-if="media.id < 0"
                    :value="media.progress.loaded"
                    :max="media.progress.total"
                    show-progress
                    animated
                    style="width: 100%"
                  ></b-progress>
                </div>
              </b-col>
            </b-row>

            <div class="d-flex justify-content-end">
              <!-- <b-button variant="gray" class="mr-1">Предпросмотр</b-button> -->
              <b-button
                @click="submit"
                :disabled="!canSendForm"
                variant="primary"
                >Опубликовать</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import "quill/dist/quill.bubble.css";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, url } from "@validations";

import GoalsWidget from "./widgets/goals"

import { pickFile } from "js-pick-file";
import $utils from "@/modules/utils/";

export default {
  data() {
    return {
      required,
      integer,
      url,
      is_loading: false,
      form: {
        title: null,
        amount: null,
        platform_amount: null,
        is_percentage_income: false,
        url: null,
        key_order: null,
        key_web_master: null,
        description_content: null,
        conformation_period: null,
        advice_content: null,
        category: {},
        company: {},
        cities: [],
        showcase_location: { id: "main", title: "Общий раздел" },
        type_cities_selected: { id: "single", title: "Единичный выбор" },
        state_access: {id: "open", title: "Открытый"},
        integrations: [],
        goals: []
      },
      medias: {
        showcase: [],
        offer_screen: [],
      },
      title: "",
      editor: null,

      traffics: {
        allowed: [""],
        forbidden: [""],
      },

      snowOption: {
        theme: "bubble",
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["align", "list", "blockquote", "code-block"],
            ["link", "image"],
          ],
        },
      },
    };
  },
  props: {
    categories: Array,
    companies: Array,
    cities: Array,
    typeAllocation: Array,
    typesSelectedCities: Array,
    statesAccess: Array,
    integrations: Array
  },

  methods: {
    getCategories() {
      this.$request.get("offers/categories").then((rsp) => {
        this.categories = rsp;
      });
    },
    getCompanies() {
      this.$request.get("offers/companies").then((rsp) => {
        this.companies = rsp;
      });
    },
    submit(args) {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (!this.canSendForm) return;

          let medias = [
            ...this.medias.showcase.map((media) => media.id),
            ...this.medias.offer_screen.map((media) => media.id),
          ];

          let cities;
          if (typeof this.form.cities == "string") cities = this.form.cities;
          else cities = this.form.cities.map((city) => city.id);

          let integrations = this.form.integrations.map((integration) => integration.id);

          this.traffics.allowed.pop();
          this.traffics.forbidden.pop();

          this.$request
            .post("offers/add", {
              title: this.form.title,
              amount: this.form.amount,
              platform_amount: this.form.platform_amount,
              is_percentage_income: this.form.is_percentage_income,
              category: this.form.category.id,
              company: this.form.company.id,
              showcase_location: this.form.showcase_location.id,
              url: this.form.url,
              key_order: this.form.key_order,
              key_web_master: this.form.key_web_master,
              conformation_period: this.form.conformation_period,
              description_content: this.form.description_content,
              advice_content: this.form.advice_content,
              traffics: this.traffics,
              medias: medias,
              cities: cities,
              type_cities_selected: this.form.type_cities_selected.id,
              state_access: this.form.state_access.id,
              integrations: integrations,
              goals: this.form.goals
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Предложение успешно создано`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                },
              });
              // this.$router.replace({ name: "admin-offers-list" });
            });
        }
      });
    },
    getMediaPlaceholderStyle(media) {
      return {
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "125px",
        minWidth: "125px",
        height: "125px",
        backgroundImage: `url(${media.thumb})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        borderRadius: "12px",
        margin: "0 8px",
        padding: "0 8px",
      };
    },

    prefereMediaDeleteAction(args, index, section) {
      this.medias[section].splice(index, 1);
      args.stopPropagation();
    },

    preferMediaPickerAction(section) {
      let options = {
        accept: ".jpg, .jpeg, .png",
        multiple: true,
      };

      pickFile(options).then((files) => {
        files.forEach((file) => {
          $utils.preferBeforeUploadAction(file).then((data) => {
            let object = {
              id: -1,
              type: data.type,
              file: file,
              fileName: file.name,
              size: file.size,
              extension: file.name.split(".").pop(),
              duration: data.duration,
              thumb: data.thumb,
              placeholder: data.placeholder,
              width: data.width,
              height: data.height,
              progress: {
                loaded: 0,
                total: 0,
              },
            };

            this.medias[section] = [];
            this.medias[section].push(object);

            this.$request
              .transfer(
                "offer.media.upload",
                {
                  type: data.type,
                  width: data.width,
                  height: data.height,
                  location: section,
                },
                file,
                (progress) => {
                  object.progress.total = progress.total;
                  object.progress.loaded = progress.loaded;
                }
              )
              .then((rsp) => {
                this.$set(object, "id", rsp.upload_id);
                //                                object.id = rsp.upload_id;
              });
          });
        });
      });
    },
    afterChangeGoals(goals) {
      this.form.goals = goals;
    }
  },
  computed: {
    canSendForm() {
      let banner_showcase = this.medias.showcase.filter((item) => item.id < 0);
      let banner_offer_screen = this.medias.offer_screen.filter(
        (item) => item.id < 0
      );
      if (banner_showcase > 0 || banner_offer_screen > 0) return false;
      if (this.form.goals.length == 0) return false;

      return true;
    },
  },
  components: {
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    GoalsWidget
  },
  watch: {
    "form.type_cities_selected": function () {
      if (this.form.type_cities_selected.id == "list") this.form.cities = "";
      else this.form.cities = [];
    },
    "traffics.allowed": function () {
      this.traffics.allowed.forEach((el, i) => {
        if (el.length == 0 && i !== this.traffics.allowed.length - 1)
          this.traffics.allowed.splice(i, 1);
      });
      if (this.traffics.allowed[this.traffics.allowed.length - 1] !== "")
        this.traffics.allowed.push("");
    },
    "traffics.forbidden": function () {
      this.traffics.forbidden.forEach((el, i) => {
        if (el.length == 0 && i !== this.traffics.forbidden.length - 1)
          this.traffics.forbidden.splice(i, 1);
      });
      if (this.traffics.forbidden[this.traffics.forbidden.length - 1] !== "")
        this.traffics.forbidden.push("");
    },
  },
  mounted() {
    if (this.categories.length === 0)
      this.$router.replace({ name: "admin-offers-categories" });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

.ql-editor {
  padding: 0;
  min-height: 150px;
}

.ql-tooltip {
  z-index: 9999;
}

[dir] .form-group-with-label .vs__dropdown-toggle .vs__selected {
  background-color: transparent !important;
  padding-right: 5px !important;
}
</style>
